/* eslint-disable jsx-a11y/label-has-associated-control */
/* Disabled associated control rule because it doesn't realize TextField is an input */
import {
  FormControl,
  FormHelperText,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Datepicker } from 'components/Datepicker';
import { RequiredIcon } from 'components/RequiredIcon';
import { calculateMinDate } from 'utils/calculateMinDate';

import { useCountries, useCountryStates } from '../../hooks/useCountries';
import { Checkbox } from '../Checkbox';
import { TextField } from '../TextField';
import { useStyles } from './styles';

export type InitialEmploymentFields = {
  /* eslint-disable camelcase */
  employer_name?: string;
  state_code?: string;
  state_code_iso?: string;
  city?: string;
  country_code?: string;
  country_code_iso?: string;
  title?: string;
  begin_date?: string;
  end_date?: string;
  employer_contact_name?: string;
  active?: boolean;
  designation?: string;
  ctc?: string;
  employee_code?: string;
  reason_for_leaving?: string;
  rm_designation?: string;
  rm_name?: string;
  rm_organization?: string;
  rm_contact?: string;
  job_title?: string;
  /* eslint-enable camelcase */
};

export interface EmploymentFieldProps {
  inputName(key: string): string;
  required?: boolean;
  id: string;
  index?: string;
  shouldValidate?: boolean;
  ANSIStateCode?: boolean;
  collectHistory: boolean;
  initialEmployment?: InitialEmploymentFields;
  additionalQuestionsRequired?: boolean;
}

export interface EmploymentValues {
  // ignoring camelcase since these correspond to the name the back end is expecting
  /* eslint-disable camelcase */
  employer_name: string;
  state_code: string;
  state_code_iso: string;
  city: string;
  country_code: string;
  country_code_iso: string;
  title: string;
  begin_date: string;
  end_date: string;
  employer_contact_name: string;
  active?: boolean;
  designation?: string;
  ctc?: string;
  employee_code?: string;
  reason_for_leaving?: string;
  rm_designation?: string;
  rm_name?: string;
  rm_organization?: string;
  rm_contact?: string;
  job_title: string;
  /* eslint-enable camelcase */
}

const hasInput = (values: EmploymentValues): boolean =>
  Object.values(values).some(
    val => typeof val === 'string' && val.trim().length > 0,
  );

export const EmploymentField: React.VFC<EmploymentFieldProps> = ({
  inputName,
  required = false,
  id,
  index,
  shouldValidate,
  collectHistory = true,
  initialEmployment,
  additionalQuestionsRequired = false,
}) => {
  const emptyEmploymentFields: EmploymentValues = {
    employer_name: '',
    state_code: '',
    state_code_iso: '',
    city: '',
    country_code: '',
    country_code_iso: '',
    title: '',
    begin_date: '',
    end_date: '',
    employer_contact_name: '',
    ctc: '',
    employee_code: '',
    reason_for_leaving: '',
    rm_designation: '',
    rm_name: '',
    rm_organization: '',
    rm_contact: '',
    job_title: '',
    active: false,
  };

  const intl = useIntl();
  const styles = useStyles();
  const { result } = useCountries();
  const [employmentFields, setEmploymentFields] = useState(
    emptyEmploymentFields,
  );
  const [showRequiredLabel, setShowRequiredLabel] = useState(false);

  const hasUserInput = useRef(false);

  const { result: stateResult } = useCountryStates(
    employmentFields.country_code_iso,
  );

  const RequiredLabel: React.VFC<{ text: string }> = ({ text }) => {
    return (
      <div className={styles.requiredLabel}>
        <Typography component="span">{text}</Typography>
        {showRequiredLabel && (
          <RequiredIcon
            data-testid="required-icon"
            className={styles.requiredIcon}
            color="error"
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (initialEmployment && !hasUserInput.current) {
      hasUserInput.current = true;
      const newEmploymentFields = {
        employer_name: initialEmployment?.employer_name ?? '',
        city: initialEmployment?.city ?? '',
        state_code: initialEmployment?.state_code ?? '',
        state_code_iso: initialEmployment?.state_code_iso ?? '',
        country_code: initialEmployment?.country_code ?? '',
        country_code_iso: initialEmployment?.country_code_iso ?? '',
        title: initialEmployment?.title ?? '',
        begin_date: initialEmployment?.begin_date ?? '',
        end_date: initialEmployment?.end_date ?? '',
        employer_contact_name: initialEmployment?.employer_contact_name ?? '',
        designation: initialEmployment?.designation ?? '',
        ctc: initialEmployment?.ctc ?? '',
        employee_code: initialEmployment?.employee_code ?? '',
        reason_for_leaving: initialEmployment?.reason_for_leaving ?? '',
        rm_designation: initialEmployment?.rm_designation ?? '',
        rm_name: initialEmployment?.rm_name ?? '',
        rm_organization: initialEmployment?.rm_organization ?? '',
        rm_contact: initialEmployment?.rm_contact ?? '',
        job_title: initialEmployment?.job_title ?? '',
      };
      setEmploymentFields(newEmploymentFields);
    }
  }, [initialEmployment]);

  useEffect(() => {
    const fieldHasValue = hasInput(employmentFields);

    if (fieldHasValue || required) {
      setShowRequiredLabel(true);
    } else {
      setShowRequiredLabel(false);
    }
  }, [employmentFields, required]);

  const stripPrefix = useCallback(
    (inputId: string) => {
      return inputId.replace('autocomplete_', '').replace(`${id}-`, '');
    },
    [id],
  );

  const handleInputs = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, id: inputId } = event.target;
      const key = inputId === id ? 'employer_name' : stripPrefix(inputId);
      if (key === 'country_code_iso' || key === 'state_code_iso') {
        return false;
      }
      if (key !== 'active') {
        setEmploymentFields(oldEmployment => ({
          ...oldEmployment,
          [key]: value,
        }));
      }
      return true;
    },
    [id, stripPrefix],
  );

  const handleCountryChange = useCallback(
    (event: React.ChangeEvent<{ name?: string }>) => {
      const selectElement = event.target as HTMLSelectElement;
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const countryName = selectedOption.text;
      const countryCode = selectElement.value;

      if (event) {
        setEmploymentFields(oldEmployment => ({
          ...oldEmployment,
          country_code: countryName,
          country_code_iso: countryCode,
        }));
      }
    },
    [],
  );

  const handleStateChange = useCallback(
    (event: React.ChangeEvent<{ name?: string }>) => {
      const selectElement = event.target as HTMLSelectElement;
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const stateName = selectedOption.text;
      const stateCode = selectElement.value;

      if (event) {
        setEmploymentFields(oldEmployment => ({
          ...oldEmployment,
          state_code: stateCode,
          state_code_iso: stateCode,
          state_code_name: stateName,
        }));
      }
    },
    [],
  );

  const stateOptions = useCallback(() => {
    if (stateResult.status === 'ready') {
      return stateResult.data.map(([state, stateCode]) => ({
        label: state,
        value: stateCode,
        default: false,
      }));
    }
    return [];
  }, [stateResult]);

  const emptyStateList = Boolean(
    stateResult.status === 'ready' && stateResult.data.length < 1,
  );

  const countryError = Boolean(
    required && shouldValidate && !employmentFields.country_code,
  );

  const stateError = Boolean(
    required &&
      shouldValidate &&
      employmentFields.country_code &&
      !employmentFields.state_code_iso &&
      !emptyStateList,
  );

  const handleActiveCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = !!e.target.checked;
    const endDateValue = value ? '' : employmentFields.end_date;
    setEmploymentFields(oldEmployment => ({
      ...oldEmployment,
      active: value,
      end_date: endDateValue,
    }));
  };

  const nestedName = useCallback(
    (field?: keyof EmploymentValues): string => {
      const nestedFieldDepth = index ? `[${index}]` : '';
      return field
        ? `${inputName(id)}${nestedFieldDepth}[${field}]`
        : `${inputName(id)}${nestedFieldDepth}`;
    },
    [id, inputName, index],
  );

  const forceRequire = required || hasInput(employmentFields);

  const showError = (value: string) =>
    forceRequire && shouldValidate && value.length === 0;

  return (
    <div
      onChange={handleInputs}
      data-employmentroot
      id={index ? `employment_field_${index}` : 'employment_field'}
    >
      <FormControl
        component="fieldset"
        className={styles.addressRoot}
        variant="outlined"
      >
        <label>
          <RequiredLabel
            text={intl.formatMessage({
              defaultMessage: 'Job Title',
              description: 'Title of Job',
            })}
          />
          <TextField
            id={`${id}-job_title`}
            name={nestedName('job_title')}
            required={forceRequire}
            error={showError(employmentFields.job_title)}
            value={employmentFields.job_title}
            valueMissingText="Job Title is required"
            inputProps={{ maxLength: 90 }}
          />
        </label>
        <label htmlFor={id}>
          <RequiredLabel
            text={intl.formatMessage({
              defaultMessage: 'Employer Name',
              description: 'Name of employer',
            })}
          />
          <TextField
            id={`${id}`}
            name={nestedName('employer_name')}
            required={forceRequire}
            error={showError(employmentFields.employer_name)}
            value={employmentFields.employer_name}
            valueMissingText="Employer name is required"
            inputProps={{ maxLength: 50 }}
          />
        </label>
        <label>
          <RequiredLabel
            text={intl.formatMessage({
              defaultMessage: 'Employer Contact Name',
              description: 'Employer Contact Name text field',
            })}
          />
          <TextField
            id={`${id}-employer_contact_name`}
            name={nestedName('employer_contact_name')}
            required={forceRequire}
            error={showError(employmentFields.employer_contact_name)}
            value={employmentFields.employer_contact_name}
            valueMissingText="Employer Contact Name is required"
            inputProps={{ maxLength: 90 }}
          />
        </label>
        <FormControl variant="outlined">
          <label>
            <RequiredLabel
              text={intl.formatMessage({
                defaultMessage: 'Country',
                description: 'Country dropdown',
              })}
            />
            <Select
              fullWidth
              name={nestedName('country_code_iso')}
              id={`${id}-country_code_iso`}
              className={styles.select}
              value={employmentFields.country_code_iso}
              native
              error={countryError}
              required={forceRequire}
              onChange={handleCountryChange}
            >
              <option value="" selected></option>
              {result.status !== 'ready' ? (
                <option value="">
                  {intl.formatMessage({
                    defaultMessage: 'Loading…',
                    description: 'Dropdown loading message',
                  })}
                </option>
              ) : (
                result.data.map(country => (
                  <option key={country[1]} value={country[1]}>
                    {country[0]}
                  </option>
                ))
              )}
            </Select>
            <FormHelperText error={countryError}>
              {countryError &&
                intl.formatMessage({
                  defaultMessage: 'Please select an option',
                  description:
                    'Message shown if a user does not select anything from the dropdown.',
                })}
            </FormHelperText>
          </label>
        </FormControl>
        <label>
          <RequiredLabel
            text={intl.formatMessage({
              defaultMessage: 'City / Town',
              description: 'City / Town text field title',
            })}
          />
          <TextField
            id={`${id}-city`}
            name={nestedName('city')}
            required={forceRequire}
            error={showError(employmentFields.city)}
            value={employmentFields.city}
            valueMissingText="City / Town is required"
            inputProps={{ maxLength: 50 }}
          />
        </label>
        <FormControl variant="outlined">
          <label>
            {required && stateOptions().length === 0 ? (
              <Typography>
                <FormattedMessage
                  defaultMessage="State / Province"
                  description="State / Province text field title"
                />
              </Typography>
            ) : (
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'State / Province',
                  description: 'State / Province text field title',
                })}
              />
            )}
            <Select
              fullWidth
              id={`${id}-state_code_iso`}
              name={nestedName('state_code_iso')}
              className={styles.select}
              disabled={result.status !== 'ready'}
              value={employmentFields.state_code_iso}
              native
              required={!emptyStateList}
              error={stateError}
              onChange={handleStateChange}
            >
              <option value="" selected></option>
              {stateResult.status !== 'ready' ? (
                <option value="">
                  {intl.formatMessage({
                    defaultMessage: 'Loading…',
                    description: 'Dropdown loading message',
                  })}
                </option>
              ) : (
                stateResult.data.map(state => (
                  <option key={state[1]} value={state[1]}>
                    {state[0]}
                  </option>
                ))
              )}
            </Select>
            <FormHelperText error={stateError}>
              {stateError &&
                intl.formatMessage({
                  defaultMessage: 'Please select an option',
                  description:
                    'Message shown if a user does not select anything from the dropdown.',
                })}
            </FormHelperText>
          </label>
        </FormControl>
        {additionalQuestionsRequired && (
          <>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'Designation',
                  description: 'Designation text field',
                })}
              />
              <TextField
                id={`${id}-designation`}
                name={nestedName('designation')}
                required={forceRequire}
                error={showError(employmentFields.designation ?? '')}
                value={employmentFields.designation}
                valueMissingText="Designation is required"
                inputProps={{ maxLength: 90 }}
              />
            </label>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'CTC',
                  description: 'CTC text field',
                })}
              />
              <TextField
                id={`${id}-ctc`}
                name={nestedName('ctc')}
                required={forceRequire}
                error={showError(employmentFields.ctc ?? '')}
                value={employmentFields.ctc}
                valueMissingText="CTC is required"
                inputProps={{ maxLength: 90 }}
              />
            </label>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'Employee Code',
                  description: 'Employee Code text field',
                })}
              />
              <TextField
                id={`${id}-employee_code`}
                name={nestedName('employee_code')}
                required={forceRequire}
                error={showError(employmentFields.employee_code ?? '')}
                value={employmentFields.employee_code}
                valueMissingText="Employee Code is required"
                inputProps={{ maxLength: 90 }}
              />
            </label>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'Reason For Leaving',
                  description: 'Designation text field',
                })}
              />
              <TextField
                id={`${id}-reason_for_leaving`}
                name={nestedName('reason_for_leaving')}
                required={forceRequire}
                error={showError(employmentFields.reason_for_leaving ?? '')}
                value={employmentFields.reason_for_leaving}
                valueMissingText="Reason for leaving is required"
                inputProps={{ maxLength: 90 }}
              />
            </label>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'RM Name',
                  description: 'RM Name text field',
                })}
              />
              <TextField
                id={`${id}-rm_name`}
                name={nestedName('rm_name')}
                required={forceRequire}
                error={showError(employmentFields.rm_name ?? '')}
                value={employmentFields.rm_name}
                valueMissingText="Reason for leaving is required"
                inputProps={{ maxLength: 90 }}
              />
            </label>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'RM Designation',
                  description: 'RM Designation text field',
                })}
              />
              <TextField
                id={`${id}-rm_designation`}
                name={nestedName('rm_designation')}
                required={forceRequire}
                error={showError(employmentFields.rm_designation ?? '')}
                value={employmentFields.rm_designation}
                valueMissingText="RM Designation is required"
                inputProps={{ maxLength: 90 }}
              />
            </label>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'RM Organization',
                  description: 'RM Organization text field',
                })}
              />
              <TextField
                id={`${id}-rm_organization`}
                name={nestedName('rm_organization')}
                required={forceRequire}
                error={showError(employmentFields.rm_organization ?? '')}
                value={employmentFields.rm_organization}
                valueMissingText="RM Organization is required"
                inputProps={{ maxLength: 90 }}
              />
            </label>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'RM Contact',
                  description: 'RM Contact text field',
                })}
              />
              <TextField
                id={`${id}-rm_contact`}
                name={nestedName('rm_contact')}
                required={forceRequire}
                error={showError(employmentFields.rm_contact ?? '')}
                value={employmentFields.rm_contact}
                valueMissingText="RM Contact is required"
                inputProps={{ maxLength: 90 }}
              />
            </label>
          </>
        )}
        {collectHistory && (
          <>
            <label>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'Begin Date',
                  description: 'Begin date field',
                })}
              />
              <Datepicker
                id={`${id}-begin_date`}
                name={nestedName('begin_date')}
                required={forceRequire}
                error={showError(employmentFields.begin_date)}
                value={employmentFields.begin_date}
                inputProps={{ max: calculateMinDate() }}
                helperText="Beginning date of employment"
              />
            </label>
            <label hidden={!!employmentFields.active}>
              <RequiredLabel
                text={intl.formatMessage({
                  defaultMessage: 'End Date',
                  description: 'End date field',
                })}
              />
              <Datepicker
                id={`${id}-end_date`}
                name={nestedName('end_date')}
                required={forceRequire && !employmentFields.active}
                error={showError(employmentFields.end_date)}
                value={employmentFields.end_date}
                inputProps={{
                  min: employmentFields.begin_date,
                  max: calculateMinDate(),
                }}
                helperText="Ending date of employment"
              />
            </label>
            <label>
              <div className={styles.activeCheckboxContainer}>
                <div className={styles.activeCheckbox}>
                  <Checkbox
                    onChange={handleActiveCheckbox}
                    id={`${id}-active`}
                    name={nestedName('active')}
                    checked={!!employmentFields.active}
                    value
                  />
                </div>
                <div>
                  <Typography>
                    <FormattedMessage
                      defaultMessage="I still work here"
                      description="Active employment checkbox"
                    />
                  </Typography>
                </div>
              </div>
            </label>
          </>
        )}
        <input
          type="hidden"
          name={nestedName('employer_name')}
          value={employmentFields.employer_name}
        />
        <input
          type="hidden"
          name={nestedName('job_title')}
          value={employmentFields.job_title}
        />
        <input
          type="hidden"
          name={nestedName('city')}
          value={employmentFields.city}
        />
        <input
          type="hidden"
          name={nestedName('employer_contact_name')}
          value={employmentFields.employer_contact_name}
        />
        <input
          type="hidden"
          name={nestedName('country_code')}
          value={employmentFields.country_code}
        />
        <input
          type="hidden"
          name={nestedName('state_code')}
          value={employmentFields.state_code}
        />
        <input
          type="hidden"
          name={nestedName('country_code_iso')}
          value={employmentFields.country_code_iso}
        />
        <input
          type="hidden"
          name={nestedName('state_code_iso')}
          value={employmentFields.state_code_iso}
        />
        {additionalQuestionsRequired && (
          <>
            <input
              type="hidden"
              name={nestedName('designation')}
              value={employmentFields.designation}
            />
            <input
              type="hidden"
              name={nestedName('ctc')}
              value={employmentFields.ctc}
            />
            <input
              type="hidden"
              name={nestedName('employee_code')}
              value={employmentFields.employee_code}
            />
            <input
              type="hidden"
              name={nestedName('reason_for_leaving')}
              value={employmentFields.reason_for_leaving}
            />
            <input
              type="hidden"
              name={nestedName('rm_name')}
              value={employmentFields.rm_name}
            />
            <input
              type="hidden"
              name={nestedName('rm_designation')}
              value={employmentFields.rm_designation}
            />
            <input
              type="hidden"
              name={nestedName('rm_organization')}
              value={employmentFields.rm_organization}
            />
            <input
              type="hidden"
              name={nestedName('rm_contact')}
              value={employmentFields.rm_contact}
            />
          </>
        )}
      </FormControl>
    </div>
  );
};

/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Modal,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FileUpload as FileUploadFields } from 'api-clients/monolith';
import cx from 'classnames';
import { Close } from 'icons/fds';
import { isUndefined } from 'lodash';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AddressField } from 'components/AddressField';
import { InitialAddressFields } from 'components/AddressField/AddressField';
import { Checkbox } from 'components/Checkbox';
import {
  EmploymentField,
  InitialEmploymentFields,
} from 'components/EmploymentField';
import { MultiFileUpload } from 'components/MultiFileUpload';
import { RequiredIcon } from 'components/RequiredIcon';

import {
  AllTheChildrenValues,
  HistoryFieldMeta,
  isAllTheChildrenValuesKey,
  State,
} from '../reducer';

export const isHistoryFieldMetaKey = (
  slug: string,
): slug is keyof HistoryFieldMeta => {
  return [
    'startMonth',
    'startYear',
    'endMonth',
    'endYear',
    'active',
    'covered',
    'validDates',
    'noHistory',
    'childComponentValues',
  ].includes(slug);
};

const useViewContainerStyles = makeStyles(theme => ({
  historyFieldView: {
    alignSelf: 'stretch',
    // background: theme.palette.common.gray100,
    background: 'white',
    borderRadius: '5px',
    color: theme.palette.common.gray800,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    textAlign: 'center',

    // TODO: Remove
    maxWidth: '358px',
  },
}));

const ViewContainer: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  const styles = useViewContainerStyles();
  return (
    <div
      className={cx(className, styles.historyFieldView)}
      data-testid="container"
    >
      {children}
    </div>
  );
};

export interface HistoryFieldViewProps {
  className?: string;
  inputName(key: string): string;
  id: string;
  state: State;
  required?: boolean;
  childField: string;
  addHistory: () => void;
  confirmDelete: () => void;
  deleteHistory: (history: HistoryFieldMeta) => void;
  editHistory: (history: HistoryFieldMeta) => void;
  addNoHistory: (history: HistoryFieldMeta) => void;
  discardHistoryChanges: () => void;
  saveHistory: (event: React.FormEvent) => void;
  inputChanged: (event: React.FormEvent) => void;
  shouldValidate?: boolean;
  uploadFields?: FileUploadFields;
  dataFieldId: number;
  allowFileUpload?: boolean;
  fileUploadRequired?: boolean;
  fileUploadHint?: string;
  additionalPartnerEmploymentQuestionsRequired?: boolean;
  showCountyField?: boolean;
}

export const useStyles = makeStyles(theme => ({
  modalContainer: {
    padding: theme.spacing(3),
  },
  formContainer: {
    padding: theme.spacing(3),
  },
  modalBox: {
    position: 'absolute',
    width: 'calc(100% - 16px)',
    maxWidth: '432px',
    left: '50%',
    top: '20px',
    transform: 'translate(-50%, 0%)',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    borderRadius: theme.misc.borderRadius,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '32px',
    justifyContent: 'space-between',
    '& p': {
      fontWeight: '500',
      fontSize: '20px',
    },
  },
  closeIcon: {
    color: theme.palette.common.gray800,
    fontSize: 16,
    margin: theme.spacing(0.25, 0, 0, 0),
    cursor: 'pointer',
  },
  modalBody: {
    padding: theme.spacing(4, 0, 4, 0),
  },
  dangerText: {
    color: theme.palette.common.red400,
  },
  loading: {
    zIndex: theme.zIndex.tooltip + 100,
  },
  dropDownContainer: {
    display: 'flex',
    paddingBottom: '16px',
    gap: '10px',
    '& div': {
      width: '100%',
    },
  },
  questionTitle: {
    paddingBottom: '8px',
  },
  checkboxContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '28px',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& button': {
      border: '1px solid #D6D9E4',
      boxShadow: '0px 1px 2px rgba(19, 30, 64, 0.08)',
      borderRadius: '5px',
    },
  },
  fullWidth: {
    width: '100%',
  },
  allHistoriesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    background: 'white',
    '& button': {
      background: 'white',
      color: '#141414',
      border: '1px solid #CCCCCC',
      boxShadow: '0px 1px 2px rgba(19, 30, 64, 0.08)',
    },
  },
  validHistoryContainer: {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: '16px',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    '& .address_no': {
      fontSize: '18px',
      fontWeight: '500',
      paddingBottom: '16px',
    },
    '& .address_1': {
      fontSize: '18px',
      fontWeight: '500',
    },
    '& .address_2, .address_3, .address_4': {
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  validGapContainer: {
    background: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 16px 16px 16px',
    borderRadius: '5px',
    gap: '16px',
    '& div': {
      paddingTop: '16px',
    },
  },
  invalidGapContainer: {
    // background: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 16px 16px 16px',
    borderRadius: '5px',
    border: '1px solid #C20D42',
    background: '#F5F5F5',
    gap: '16px',
    '& div': {
      paddingTop: '16px',
    },
  },
  exitingHistoryActionContainer: {
    display: 'flex',
    width: '100%',
    paddingTop: '16px',
    gap: '8px',
    '& div': {
      width: '100%',
    },
  },
  fullWidthDeleteButton: {
    width: '100%',
    color: '#C20D42 !important',
  },
  deleteWarningContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '16px',
    fontWeight: 400,
    fontSize: '16px',
    '& button': {
      background: 'white',
      color: '#141414',
      border: '1px solid #CCCCCC',
      boxShadow: '0px 1px 2px rgba(19, 30, 64, 0.08)',
    },
  },
  dropdownErrorMessage: {
    color: '#e51f58',
  },
  noHistoryCheckbox: {
    paddingBottom: '16px',
  },
  break: {
    background: '#D6D9E4',
    width: '100%',
    height: '1px',
  },
  addHistoryButtonContainer: {
    background: '#FFFFFF',
    color: 'black',
    border: '1px solid #CCCCCC',
    boxShadow: '0px 1px 2px rgba(19, 30, 64, 0.08)',
    borderRadis: '5px',
  },
  invalidHistoryContainer: {
    background: 'rgba(255, 221, 231, 0.5)',
    borderRadius: '5px',
    padding: '12px',
    textAlign: 'initial',
    '& .title': {
      fontWeight: '500',
      paddingRight: '6px',
    },
    '& .body': {
      fontWeight: '400',
    },
  },
  hiddenInputsContainer: {
    display: 'none',
  },
  requiredLabel: {
    display: 'flex',
    gap: '8px',
    marginBlockEnd: '8px',
  },
  requiredIcon: {
    transform: 'translateY(8px)',
    fontSize: '0.5rem',
  },
  uploadHint: {
    color: theme.palette.common.gray600,
    fontStyle: 'italic',
  },
}));

const historyKey = (history: HistoryFieldMeta): string =>
  `${history.startYear}${history.startMonth}${history.endYear}${history.endMonth}`;

export const HistoryFieldView: React.VFC<HistoryFieldViewProps> = ({
  inputName,
  id,
  className,
  state,
  required,
  childField,
  addHistory,
  confirmDelete,
  deleteHistory,
  editHistory,
  addNoHistory,
  discardHistoryChanges,
  saveHistory,
  inputChanged,
  shouldValidate,
  uploadFields,
  dataFieldId,
  allowFileUpload,
  fileUploadRequired,
  fileUploadHint,
  showCountyField,
  additionalPartnerEmploymentQuestionsRequired = false,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const RequiredLabel: React.VFC<{ text: string; required?: boolean }> = ({
    text,
    required = true,
  }) => {
    return (
      <div className={styles.requiredLabel}>
        <Typography component="span">{text}</Typography>
        {required && (
          <RequiredIcon
            data-testid="required-icon"
            className={styles.requiredIcon}
            color="error"
          />
        )}
      </div>
    );
  };

  const getMonthName = (monthNumber: number) => {
    if (monthNumber >= 1 && monthNumber <= 12) {
      const formatter = new Intl.DateTimeFormat(navigator.language, {
        month: 'long',
      });
      return formatter.format(new Date(2023, monthNumber - 1)); // any year works really
    }
    return 'Invalid month number';
  };

  const years = (selectedStartYear: number | undefined) => {
    const currentYear = new Date().getFullYear(); // get current year
    const years = [];
    for (let i = 0; i < 25; i++) {
      const year = currentYear - i;
      if (isUndefined(selectedStartYear) || selectedStartYear <= year) {
        years.push({
          label: year.toString(),
          value: year.toString(),
          default: false,
        });
      }
    }
    return years;
  };

  const months = (currentDate: Date, selectedYear: number | undefined) => {
    const months = [
      { label: 'January', value: '1', default: false },
      { label: 'February', value: '2', default: false },
      { label: 'March', value: '3', default: false },
      { label: 'April', value: '4', default: false },
      { label: 'May', value: '5', default: false },
      { label: 'June', value: '6', default: false },
      { label: 'July', value: '7', default: false },
      { label: 'August', value: '8', default: false },
      { label: 'September', value: '9', default: false },
      { label: 'October', value: '10', default: false },
      { label: 'November', value: '11', default: false },
      { label: 'December', value: '12', default: false },
    ];
    if (isUndefined(selectedYear)) {
      return months;
    }
    if (selectedYear === currentDate.getFullYear()) {
      return months.slice(0, currentDate.getMonth() + 1);
    }
    return months;
  };

  const endDateDisabled = () => {
    return state.history && state.history.active;
  };

  const nestedName = useCallback(
    (field: string): string => `${inputName(id)}[][${field}]`,
    [id, inputName],
  );

  const createInputElementsFromAllTheChildrenValues = (
    obj: AllTheChildrenValues,
  ) => {
    const inputElements: JSX.Element[] = [];

    // for (const key in obj) {
    Object.keys(obj).forEach(key => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (!isAllTheChildrenValuesKey(key)) {
          return;
        }
        const value = obj[key];
        let input;

        if (typeof value === 'boolean') {
          input = (
            <input
              type="checkbox"
              name={nestedName(key)}
              checked={value}
              key={key}
            />
          );
        } else {
          input = (
            <input type="text" name={nestedName(key)} value={value} key={key} />
          );
        }

        inputElements.push(input);
      }
      // return key;
    });

    return inputElements;
  };

  const createInputElementsFromObject = (obj: HistoryFieldMeta) => {
    const inputElements: JSX.Element[] = [];

    // for (const key in obj) {
    Object.keys(obj).forEach(key => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (
          !isHistoryFieldMetaKey(key) ||
          ['covered', 'validDates'].includes(key)
        ) {
          return;
        }
        const value = obj[key];
        let input;

        if (
          typeof value === 'object' &&
          !Array.isArray(value) &&
          value !== null
        ) {
          inputElements.push(
            ...createInputElementsFromAllTheChildrenValues(value),
          );
        } else {
          if (typeof value === 'boolean') {
            input = (
              <input
                type="checkbox"
                name={nestedName(key)}
                checked={value}
                key={key}
              />
            );
          } else {
            input = (
              <input
                type="text"
                name={nestedName(key)}
                value={value}
                key={key}
              />
            );
          }

          inputElements.push(input);
        }
      }
    });

    return inputElements;
  };

  const transformChildComponentValuesToInitialAddressFields = (
    childComponent: AllTheChildrenValues,
  ): InitialAddressFields => {
    return {
      initialStreetAddress: childComponent.street_address ?? '',
      initialAddress2: childComponent.address_2,
      initialCity: childComponent.city ?? '',
      initialPostalCode: childComponent.postal_code ?? '',
      initialStateCode: childComponent.state_code ?? '',
      initialStateCodeIso: childComponent.state_code_iso,
      initialStateCodeName: childComponent.state_code_name,
      initialCountryCode: childComponent.country_code,
      initialCountryCodeIso: childComponent.country_code_iso,
    };
  };

  const transformChildComponentValuesToInitialEmploymentFields = (
    childComponent: AllTheChildrenValues,
  ): InitialEmploymentFields => {
    return {
      employer_name: childComponent.employer_name ?? '',
      state_code: childComponent.state_code ?? '',
      state_code_iso: childComponent.state_code_iso ?? '',
      city: childComponent.city ?? '',
      country_code: childComponent.country_code ?? '',
      country_code_iso: childComponent.country_code_iso ?? '',
      title: childComponent.title ?? '',
      employer_contact_name: childComponent.employer_contact_name ?? '',
      designation: childComponent?.designation ?? '',
      ctc: childComponent?.ctc ?? '',
      employee_code: childComponent?.employee_code ?? '',
      reason_for_leaving: childComponent?.reason_for_leaving ?? '',
      rm_designation: childComponent?.rm_designation ?? '',
      rm_name: childComponent?.rm_name ?? '',
      rm_organization: childComponent?.rm_organization ?? '',
      rm_contact: childComponent?.rm_contact ?? '',
      job_title: childComponent?.job_title ?? '',
    };
  };

  const historyDisplayArray = () => {
    const datesArray = [...state.histories];

    datesArray.sort((a, b) => {
      if (a.startYear === b.startYear) {
        return a.startMonth - b.startMonth;
      }

      return a.startYear - b.startYear;
    });

    let highestYearSeen = 0;
    let highestMonthSeen = 0;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const extendedDateRanges = datesArray.reduce<HistoryFieldMeta[]>(
      (acc, curr, index) => {
        acc.push(curr); // Add the current object to the new array
        if (curr.active) {
          highestYearSeen = currentYear;
          highestMonthSeen = currentMonth + 1;
        } else if (highestYearSeen < curr.endYear) {
          highestYearSeen = curr.endYear;
          highestMonthSeen = curr.endMonth;
        } else if (
          highestYearSeen === curr.endYear &&
          highestMonthSeen < curr.endMonth
        ) {
          highestMonthSeen = curr.endMonth;
        }

        if (index !== datesArray.length - 1) {
          const next = datesArray[index + 1];

          if (
            (!(
              highestYearSeen > next.startYear ||
              (highestYearSeen === next.startYear &&
                highestMonthSeen >= next.startMonth)
            ) &&
              curr.endYear < next.startYear &&
              !(
                curr.endMonth === 12 &&
                next.startMonth === 1 &&
                next.startYear === curr.endYear + 1
              )) ||
            (curr.endYear === next.startYear &&
              curr.endMonth < next.startMonth - 1)
          ) {
            const gapObject = {
              startMonth: curr.endMonth === 12 ? 1 : curr.endMonth + 1,
              startYear: curr.endMonth === 12 ? curr.endYear + 1 : curr.endYear,
              endMonth: next.startMonth === 1 ? 12 : next.startMonth - 1,
              endYear:
                next.startMonth === 1 ? next.startYear - 1 : next.startYear,
              covered: false,
              active: false,
              validDates: true,
              noHistory: false,
              childComponentValues: {},
            };

            acc.push(gapObject);
          }
        }

        return acc;
      },
      [],
    );
    const lastDate = datesArray[datesArray.length - 1];

    if (
      lastDate &&
      !lastDate.active &&
      !(lastDate.endYear >= currentYear && lastDate.endMonth >= currentMonth) &&
      !(
        highestYearSeen > currentYear ||
        (highestYearSeen === currentYear && highestMonthSeen >= currentMonth)
      )
    ) {
      const gapObject = {
        startMonth: lastDate.endMonth === 12 ? 1 : lastDate.endMonth + 1,
        startYear:
          lastDate.endMonth === 12 ? lastDate.endYear + 1 : lastDate.endYear,
        endMonth: currentMonth + 1,
        endYear: currentYear,
        covered: false,
        active: true,
        validDates: true,
        noHistory: false,
        childComponentValues: {},
      };

      extendedDateRanges.push(gapObject);
    }

    return extendedDateRanges;
  };

  const formIsValidAndCanBeSubmitted = () => {
    // The value of state.numberOfYearsMissing will be 0 or less if there are no missing years.  If you're off by 1 month it'll return 1.
    return (
      historyDisplayArray().filter(obj => !obj.covered).length === 0 &&
      parseInt(state.numberOfYearsMissing, 10) <= 0
    );
  };

  switch (state.status) {
    case 'InitState':
    case 'InvalidState':
      return (
        <ViewContainer className={className}>
          <div className={styles.hiddenInputsContainer}>
            {!formIsValidAndCanBeSubmitted() ? (
              <input type="text" required={required}></input>
            ) : (
              <>
                {historyDisplayArray().map(history => {
                  return createInputElementsFromObject(history);
                })}
              </>
            )}
          </div>
          {state.histories.length === 0 && (
            <>
              {childField === 'address' ? (
                <FormattedMessage
                  defaultMessage="No addresses entered."
                  description="Message to user when they have not entered any addresses in their history"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="No employments entered."
                  description="Message to user when they have not entered any employments in their history"
                />
              )}
            </>
          )}
          {shouldValidate && !formIsValidAndCanBeSubmitted() && (
            <div className={styles.invalidHistoryContainer}>
              <span className="title">
                <FormattedMessage
                  defaultMessage="History incomplete:"
                  description="Message to user when they have not entered enough years of history"
                />
              </span>
              <span className="body">
                <FormattedMessage
                  defaultMessage="Please provide full {numberOfYears} year history to continue."
                  description="Message to user when they have not entered enough years of history"
                  values={{ numberOfYears: state.yearsOfValidation }}
                />
              </span>
            </div>
          )}

          <div className={styles.allHistoriesContainer}>
            {historyDisplayArray()
              .reverse()
              .map(history => {
                return (
                  <React.Fragment key={historyKey(history)}>
                    {history.covered ? (
                      <div className={styles.validHistoryContainer}>
                        {history.noHistory ? (
                          <>
                            <div className="address_no">
                              No history for this time period
                            </div>
                          </>
                        ) : (
                          <>
                            {childField !== 'address' && (
                              <div>
                                {history.childComponentValues.job_title}
                              </div>
                            )}
                            <div className="address_1">
                              {childField === 'address'
                                ? history.childComponentValues.street_address
                                : history.childComponentValues.employer_name}
                            </div>
                            <div className="address_2">
                              {childField === 'address'
                                ? history.childComponentValues.address_2
                                : history.childComponentValues
                                    .employer_contact_name}
                            </div>
                            <div className="address_3">
                              {history.childComponentValues.city}
                              {', '}
                              {history.childComponentValues.state_code_iso}{' '}
                              {history.childComponentValues.postal_code}
                            </div>
                          </>
                        )}
                        <div className="address_4">
                          {getMonthName(history.startMonth)} {history.startYear}{' '}
                          -{' '}
                          {history.active
                            ? 'Current'
                            : `${getMonthName(history.endMonth)} ${
                                history.endYear
                              }`}
                        </div>
                        <div className={styles.exitingHistoryActionContainer}>
                          <div>
                            <Button
                              className={styles.fullWidth}
                              color="primary"
                              variant="outlined"
                              onClick={() => editHistory(history)}
                            >
                              <FormattedMessage
                                defaultMessage="Edit"
                                description="Label for button allowing user to edit an address for a covered time frame in their history"
                              />
                            </Button>
                          </div>
                          <div>
                            {' '}
                            <Button
                              className={styles.fullWidthDeleteButton}
                              color="primary"
                              variant="outlined"
                              onClick={() => deleteHistory(history)}
                            >
                              <FormattedMessage
                                defaultMessage="Delete"
                                description="Label for button allowing user to delete an history for a covered time frame in their history"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={styles.invalidGapContainer}>
                          <div>
                            {getMonthName(history.startMonth)}{' '}
                            {history.startYear} -{' '}
                            {getMonthName(history.endMonth)} {history.endYear}
                          </div>
                          <Button
                            className={styles.fullWidth}
                            color="primary"
                            variant="outlined"
                            onClick={() => editHistory(history)}
                          >
                            <FormattedMessage
                              defaultMessage="Add history in this period"
                              description="Label for button allowing user to enter a new history for an uncovered time frame in their history"
                            />
                          </Button>
                          <Button
                            className={styles.fullWidth}
                            color="primary"
                            variant="outlined"
                            onClick={() => addNoHistory(history)}
                          >
                            <FormattedMessage
                              defaultMessage="I had no history during this time"
                              description="Label for button allowing user to mark this time range as uncovered in their history"
                            />
                          </Button>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
          <div className={styles.break}></div>
          <Button
            className={styles.addHistoryButtonContainer}
            color="primary"
            variant="outlined"
            onClick={addHistory}
          >
            {childField === 'address' ? (
              <FormattedMessage
                defaultMessage="Add address history"
                description="Label for button allowing user to enter a new address in their history"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Add employment history"
                description="Label for button allowing user to enter a new job in their history"
              />
            )}
          </Button>
        </ViewContainer>
      );
    case 'DeletingHistoryState':
      return (
        <ViewContainer className={className}>
          <Modal open>
            <div className={styles.modalBox}>
              <div className={styles.modalHeader}>
                <div>
                  <Typography>
                    {childField === 'address' ? (
                      <FormattedMessage
                        defaultMessage="Delete Address History"
                        description="Title of pop up modal offering delete the address."
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Delete Employment History"
                        description="Title of pop up modal offering delete the employment."
                      />
                    )}
                  </Typography>
                </div>
                <div>
                  <Close
                    className={styles.closeIcon}
                    onClick={discardHistoryChanges}
                  />
                </div>
              </div>
              <div className={styles.deleteWarningContainer}>
                <div>
                  <FormattedMessage
                    defaultMessage="Deleting the history can’t be undone, and all data entered for this section will be lost."
                    description="Body 1/2 of pop up modal asking to confirm the delete of a history."
                  />
                </div>
                <div>
                  <FormattedMessage
                    defaultMessage="Are you sure you want to delete this history?"
                    description="Body 2/2 of pop up modal asking to confirm the delete of a history."
                  />
                </div>
                <div>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={confirmDelete}
                    className={styles.fullWidthDeleteButton}
                  >
                    {childField === 'address' ? (
                      <FormattedMessage
                        defaultMessage="Delete address history"
                        description="Label for button allowing user to delete an existing address in their history"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Delete employment history"
                        description="Label for button allowing user to delete an existing job in their history"
                      />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </ViewContainer>
      );
    case 'EditingHistoryState':
    case 'AddingHistoryState':
      return (
        <ViewContainer className={className}>
          <Dialog open fullScreen className={styles.modalContainer}>
            <div className={styles.formContainer}>
              <div className={styles.modalHeader}>
                <div>
                  {state.status === 'EditingHistoryState' ? (
                    <Typography>
                      {childField === 'address' ? (
                        <FormattedMessage
                          defaultMessage="Edit Address History"
                          description="Title of pop up modal offering to leave the current modal"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Edit Employment History"
                          description="Title of pop up modal offering to leave the current modal"
                        />
                      )}
                    </Typography>
                  ) : (
                    <Typography>
                      {childField === 'address' ? (
                        <FormattedMessage
                          defaultMessage="Add Address History"
                          description="Title of pop up modal offering to leave the current modal"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Add Employment History"
                          description="Title of pop up modal offering to leave the current modal"
                        />
                      )}
                    </Typography>
                  )}
                </div>
                <div>
                  <Close
                    className={styles.closeIcon}
                    onClick={discardHistoryChanges}
                  />
                </div>
              </div>
              <div data-add-address-state>
                <form
                  id="addHistoryForm"
                  onSubmit={saveHistory}
                  onChange={inputChanged}
                  noValidate
                >
                  <FormControlLabel
                    className={styles.noHistoryCheckbox}
                    control={
                      <Checkbox
                        id="noHistory"
                        name="noHistory"
                        checked={state.history.noHistory}
                        value
                      />
                    }
                    label={intl.formatMessage({
                      defaultMessage: 'I had no history during this time',
                      description:
                        'Checkbox indicating there was no entry for this time frame',
                    })}
                  />
                  <div className={styles.dropDownContainer}>
                    <div>
                      <FormControl variant="outlined">
                        <label>
                          <RequiredLabel
                            text={intl.formatMessage({
                              defaultMessage: 'Start Month',
                              description: 'Started history month field',
                            })}
                          />
                          <Select
                            native
                            id="startMonth"
                            name="startMonth"
                            required
                            value={state.history.startMonth}
                            error={
                              state.submitted &&
                              (!(state.history.startMonth > 0) ||
                                !state.history.validDates)
                            }
                            inputProps={{
                              name: 'startMonth',
                            }}
                          >
                            <option aria-label="None" value="" />
                            {months(new Date(), state.history.startYear).map(
                              month => {
                                return (
                                  <option key={month.value} value={month.value}>
                                    {month.label}
                                  </option>
                                );
                              },
                            )}
                          </Select>
                          {state.submitted &&
                            !(state.history.startMonth > 0) && (
                              <FormHelperText
                                className={styles.dropdownErrorMessage}
                              >
                                {intl.formatMessage({
                                  defaultMessage: 'Missing value',
                                  description:
                                    'History start date missing error',
                                })}
                              </FormHelperText>
                            )}
                          {state.history.startMonth > 0 &&
                            !state.history.validDates && (
                              <FormHelperText
                                className={styles.dropdownErrorMessage}
                              >
                                {intl.formatMessage({
                                  defaultMessage: 'Invalid date range',
                                  description: 'History date range error',
                                })}
                              </FormHelperText>
                            )}
                        </label>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl variant="outlined">
                        <label>
                          <RequiredLabel
                            text={intl.formatMessage({
                              defaultMessage: 'Start Year',
                              description: 'Started history year field',
                            })}
                          />
                          <Select
                            native
                            id="startYear"
                            name="startYear"
                            required
                            value={state.history.startYear}
                            error={
                              state.submitted &&
                              (!(state.history.startYear > 0) ||
                                !state.history.validDates)
                            }
                            inputProps={{
                              name: 'startYear',
                            }}
                          >
                            <option aria-label="None" value="" />
                            {years(undefined).map(year => {
                              return (
                                <option key={year.value} value={year.value}>
                                  {year.label}
                                </option>
                              );
                            })}
                          </Select>
                          {state.submitted &&
                            !(state.history.startYear > 0) && (
                              <FormHelperText
                                className={styles.dropdownErrorMessage}
                              >
                                {intl.formatMessage({
                                  defaultMessage: 'Missing value',
                                  description:
                                    'History start date missing error',
                                })}
                              </FormHelperText>
                            )}
                        </label>
                      </FormControl>
                    </div>
                  </div>

                  {endDateDisabled() && (
                    <>
                      <input
                        type="hidden"
                        name="endMonth"
                        value={state.history.endMonth}
                      />
                      <input
                        type="hidden"
                        name="endYear"
                        value={state.history.endYear}
                      />
                    </>
                  )}
                  <div className={styles.dropDownContainer}>
                    <div>
                      <FormControl
                        variant="outlined"
                        disabled={endDateDisabled()}
                      >
                        <label>
                          <RequiredLabel
                            text={intl.formatMessage({
                              defaultMessage: 'End Month',
                              description: 'End history month field',
                            })}
                            required={!endDateDisabled()}
                          />
                          <Select
                            native
                            id="endMonth"
                            name="endMonth"
                            required
                            value={state.history.endMonth}
                            error={
                              state.submitted &&
                              (!(state.history.endMonth > 0) ||
                                !state.history.validDates)
                            }
                            inputProps={{
                              name: 'endMonth',
                            }}
                          >
                            <option aria-label="None" value="" />
                            {months(new Date(), state.history.endYear).map(
                              month => {
                                return (
                                  <option key={month.value} value={month.value}>
                                    {month.label}
                                  </option>
                                );
                              },
                            )}
                          </Select>
                          {!state.history.active &&
                            state.submitted &&
                            !(state.history.endMonth > 0) && (
                              <FormHelperText
                                className={styles.dropdownErrorMessage}
                              >
                                {intl.formatMessage({
                                  defaultMessage: 'Missing value',
                                  description: 'History end date missing error',
                                })}
                              </FormHelperText>
                            )}
                          {state.history.endMonth > 0 &&
                            !state.history.validDates && (
                              <FormHelperText
                                className={styles.dropdownErrorMessage}
                              >
                                {intl.formatMessage({
                                  defaultMessage: 'Invalid date range',
                                  description: 'History date range error',
                                })}
                              </FormHelperText>
                            )}
                        </label>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl
                        variant="outlined"
                        disabled={endDateDisabled()}
                      >
                        <label>
                          <RequiredLabel
                            text={intl.formatMessage({
                              defaultMessage: 'End Year',
                              description: 'End history year field',
                            })}
                            required={!endDateDisabled()}
                          />
                          <Select
                            native
                            id="endYear"
                            name="endYear"
                            required
                            value={state.history.endYear}
                            error={
                              state.submitted &&
                              (!(state.history.endYear > 0) ||
                                !state.history.validDates)
                            }
                            inputProps={{
                              name: 'endYear',
                            }}
                          >
                            <option aria-label="None" value="" />
                            {years(state.history.startYear).map(month => {
                              return (
                                <option key={month.value} value={month.value}>
                                  {month.label}
                                </option>
                              );
                            })}
                          </Select>
                          {!state.history.active &&
                            state.submitted &&
                            !(state.history.endYear > 0) && (
                              <FormHelperText
                                className={styles.dropdownErrorMessage}
                              >
                                {intl.formatMessage({
                                  defaultMessage: 'Missing value',
                                  description: 'History end date missing error',
                                })}
                              </FormHelperText>
                            )}
                        </label>
                      </FormControl>
                    </div>
                  </div>
                  <div className={styles.checkboxContainer}>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="active"
                            name="active"
                            checked={state.history.active}
                            value
                          />
                        }
                        label={intl.formatMessage({
                          defaultMessage: 'I am still here',
                          description: 'Active history checkbox field',
                        })}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div
                    id="childComponent"
                    className={
                      state.history.noHistory
                        ? styles.hiddenInputsContainer
                        : ''
                    }
                  >
                    {childField === 'address' ? (
                      <AddressField
                        id="addressField"
                        inputName={(inputName: string) => {
                          return inputName;
                        }}
                        required={!!(required && !state.history.noHistory)}
                        shouldValidate={
                          state.submitted && !state.history.noHistory
                        }
                        initialAddress={transformChildComponentValuesToInitialAddressFields(
                          state.history.childComponentValues,
                        )}
                        showCountyField={showCountyField}
                      />
                    ) : (
                      <EmploymentField
                        id="employmentField"
                        additionalQuestionsRequired={
                          additionalPartnerEmploymentQuestionsRequired
                        }
                        inputName={(inputName: string) => {
                          return inputName;
                        }}
                        required={!!(required && !state.history.noHistory)}
                        shouldValidate={
                          state.submitted && !state.history.noHistory
                        }
                        collectHistory={false}
                        initialEmployment={transformChildComponentValuesToInitialEmploymentFields(
                          state.history.childComponentValues,
                        )}
                      />
                    )}
                  </div>
                  {!state.history.noHistory &&
                    allowFileUpload &&
                    uploadFields && (
                      <div>
                        <label>
                          <Typography className={styles.requiredLabel}>
                            <FormattedMessage
                              defaultMessage="Upload documents"
                              description="Upload documents"
                            />
                            {fileUploadRequired && (
                              <RequiredIcon
                                data-testid="required-icon"
                                className={styles.requiredIcon}
                                color="error"
                              />
                            )}
                          </Typography>
                          {fileUploadHint && (
                            <Typography className={styles.uploadHint}>
                              {fileUploadHint}
                            </Typography>
                          )}
                          <MultiFileUpload
                            id="key"
                            required={!!fileUploadRequired}
                            name={nestedName('files')}
                            uploadFields={uploadFields}
                            shouldValidate={
                              state.submitted && !state.history.noHistory
                            }
                            dataFieldId={dataFieldId}
                            selectedFiles={
                              state.history.childComponentValues.files
                            }
                          />
                        </label>
                      </div>
                    )}
                  <div className={styles.actionButtonsContainer}>
                    <div>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={discardHistoryChanges}
                        fullWidth
                      >
                        <FormattedMessage
                          defaultMessage="Discard changes"
                          description="Label for button allowing user to discard the changes to adding an history"
                        />
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        fullWidth
                      >
                        {childField === 'address' ? (
                          <FormattedMessage
                            defaultMessage="Add address"
                            description="Label for button allowing user to enter a new entry in their address history"
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage="Add employment"
                            description="Label for button allowing user to enter a new entry in their employment history"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Dialog>
          {/* </Modal> */}
        </ViewContainer>
      );
    /* istanbul ignore next */
    default:
      return <></>;
  }
};

import { Box, Button } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '../../../components/Typography';
import { FormInput } from './FormInput';
import { getVisibleFields } from './logicJumpUtils';
import { useStyles } from './styles';
import { ProgressManagerProps } from './types';

type BaseProps = Pick<
  ProgressManagerProps,
  'dataFields' | 'countryCode' | 'uploadFields'
>;

interface ViewProps extends BaseProps {
  currentInput: number;
  errorOpened: boolean;
  subject?: string;
  applicantFirstName?: string;
  applicantLastName?: string;
  additionalPartnerEmploymentQuestionsRequired?: boolean;
  showCountyField?: boolean;
  isLogicJump?: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  handleCurrentChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
  handleNextClick: (event: React.MouseEvent<HTMLElement>) => void;
  handlePreviousClick: (event: React.MouseEvent<HTMLElement>) => void;
}
export const SuccessiveView: React.VFC<ViewProps> = ({
  dataFields,
  currentInput,
  subject = 'Question',
  applicantFirstName,
  applicantLastName,
  handleSubmit,
  handleCurrentChange,
  handleNextClick,
  handlePreviousClick,
  errorOpened,
  uploadFields,
  countryCode,
  additionalPartnerEmploymentQuestionsRequired,
  isLogicJump,
  showCountyField,
}) => {
  const styles = useStyles();
  const visibleFields = getVisibleFields(dataFields);
  const hiddenFields = dataFields.filter(
    dataField => dataField.type === 'hidden_field',
  );
  const setCheckboxAsChecked = () => {
    const consentBodies = document.getElementsByClassName(
      'partner-consent-body',
    );
    Array.from(consentBodies).forEach(consentBody => {
      const inputs = consentBody.querySelectorAll('input');
      inputs.forEach(input => {
        if (input.checked) {
          input.setAttribute('checked', 'checked');
        } else {
          // Create a new input element
          const newInput = document.createElement('input');
          newInput.type = input.type;
          // Optionally, set other attributes (e.g., id, name, class) as needed
          newInput.id = input.id;
          newInput.name = input.name;
          newInput.value = input.value;
          // Replace the existing input with the new input
          if (input.parentNode) {
            input.parentNode.replaceChild(newInput, input);
          }
        }
      });
    });
  };
  const isLastDataField = currentInput === visibleFields.length - 1;
  const isFirstDataField = currentInput === 0;
  const isOnlyButton = dataFields.length === 1 || isFirstDataField;

  return (
    <>
      <Typography color="gray600" variant="subtitle">
        {subject === 'Consent' ? (
          <FormattedMessage
            defaultMessage="Consent {currentQuestion} of {totalQuestions}"
            values={{
              currentQuestion: currentInput + 1,
              totalQuestions: visibleFields.length,
            }}
            description="Consent count progress"
          />
        ) : (
          !isLogicJump && (
            <FormattedMessage
              defaultMessage="Question {currentQuestion} of {totalQuestions}"
              values={{
                currentQuestion: currentInput + 1,
                totalQuestions: visibleFields.length,
              }}
              description="Question count progress"
            />
          )
        )}{' '}
      </Typography>
      <form
        onSubmit={handleSubmit}
        autoComplete="on"
        noValidate
        onChange={handleCurrentChange}
      >
        {hiddenFields.map(dataField => (
          <FormInput dataField={dataField} key={dataField.key} />
        ))}
        {visibleFields.map((dataField, i) => (
          <Box mb={4} key={dataField.key} hidden={currentInput !== i}>
            <FormInput
              dataField={dataField}
              uploadFields={uploadFields}
              showErrors={errorOpened}
              countryCode={countryCode}
              applicantFirstName={applicantFirstName}
              applicantLastName={applicantLastName}
              additionalPartnerEmploymentQuestionsRequired={
                additionalPartnerEmploymentQuestionsRequired
              }
              showCountyField={showCountyField}
            />
          </Box>
        ))}
        <Box
          mt={2}
          className={cx(styles.buttonGroup, {
            [styles.onlyOneButton]: isOnlyButton,
          })}
        >
          {isFirstDataField === false && (
            <Button
              fullWidth
              variant="outlined"
              type="button"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                window.scrollTo(0, 0);
                handlePreviousClick(event);
              }}
            >
              <FormattedMessage
                defaultMessage="Previous"
                description="Previous button label for the progress manager"
              />
            </Button>
          )}
          {isLastDataField && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                setCheckboxAsChecked();
              }}
            >
              <FormattedMessage
                defaultMessage="Submit"
                description="Submit button label for the progress manager"
              />
            </Button>
          )}
          {!isLastDataField && (
            <Button
              fullWidth
              variant="contained"
              type="button"
              color="primary"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                window.scrollTo(0, 0);
                handleNextClick(event);
              }}
            >
              <FormattedMessage
                defaultMessage="Next"
                description="Next button label for the progress manager"
              />
            </Button>
          )}
        </Box>
      </form>
    </>
  );
};
